<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card flat>
    <v-navigation-drawer width="100%">
      <v-list-item inactive two-line>
        <v-list-item-avatar rounded="0">
          <font-awesome-icon :icon="['fad', 'chart-network']" size="2x" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Mon réseau</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">Listes des membres de mon réseau</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list nav>
        <v-list-item link :to="{name: 'my-contacts'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'address-book']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>
            Mes contacts
            <v-badge v-if="pendingRequestsCount" color="secondary" :content="pendingRequestsCount" class="ml-2" />
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list-group no-action class="mb-1">
        <template #prependIcon>
          <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
        </template>
        <template v-slot:activator>
          <v-list-item-title>Mon équipe soignante</v-list-item-title>
        </template>
        <v-list-item dense sub-group link :to="{name: 'my-medical-team'}" exact>
          <v-list-item-content>
            <v-list-item-title class="font-alt">Consulter mon équipe</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense link @click="openMedicalTeamForm">
          <v-list-item-content>
            <v-list-item-title class="font-alt">Ajouter un professionnel</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action class="mb-1">
        <template #prependIcon>
          <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-class']" class="grey--text text--darken-1" />
        </template>
        <template v-slot:activator>
          <v-list-item-title>Mes groupes</v-list-item-title>
        </template>
        <v-list-item dense sub-group link :to="{name: 'my-teams'}" exact>
          <v-list-item-content>
            <v-list-item-title class="font-alt">Ma liste de groupes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense sub-group link @click="openTeamCreate">
          <v-list-item-content>
            <v-list-item-title class="font-alt">Créer un nouveau groupe</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
  import {Space} from '@/models'
  import * as Contacts from '@/modules/network/api'
  export default {
    name: 'NetworkSidebar',
    data() {
      return {
        pendingRequestsCount: null
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      }
    },
    async created() {
      Contacts.getPendingRequests()
        .then(response => {
          this.pendingRequestsCount = response.data.list.length
        })
        .catch(() => {
          this.Error()
        })
    },
    methods: {
      openTeamCreate() {
        this.$root.$emit('modal:open', {
          modal: ()=> import('@/modules/teams/components/TeamForm')
        })
      },
      openMedicalTeamForm() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/patients/components/patients/PatientMedicalTeamForm')
        })
      }
    }
  }
</script>
