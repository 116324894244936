<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <network-sidebar-professional v-if="$auth.granted('role_professionals')" />
        <network-sidebar-patient v-else />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <vue-page-transition name="fade-in-down">
          <router-view :key="$route.fullPath" />
        </vue-page-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import NetworkSidebarPatient from '@/modules/network/components/patients/NetworkSidebar'
  import NetworkSidebarProfessional from '@/modules/network/components/professionals/NetworkSidebar'
  export default {
    name: 'NetworkSection',
    components: {NetworkSidebarProfessional, NetworkSidebarPatient}
  }
</script>
