<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->
<template>
  <v-navigation-drawer v-model="localDrawer" :value="menuDrawerMobile"
                       :width="$vuetify.breakpoint.mdAndDown ? '90%' : '100%'" :class="$vuetify.breakpoint.mdAndDown ? 'navigation-mobile-container' : ''"
                       :height="$vuetify.breakpoint.lgAndUp ? 'auto' : 'calc(100% - 112px)'"
                       :permanent="$vuetify.breakpoint.lgAndUp" :fixed="$vuetify.breakpoint.mdAndDown"
  >
    <v-card flat class="h-100">
      <v-list-item inactive two-line>
        <v-list-item-avatar rounded="0">
          <font-awesome-icon :icon="['fad', 'chart-network']" size="2x" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">Mon réseau</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">Listes des membres de mon réseau</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list nav>
        <v-list-group no-action class="mb-1" value="expand">
          <template #prependIcon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'address-book']" class="grey--text text--darken-1" />
          </template>
          <template v-slot:activator>
            <v-list-item-title>Mes contacts</v-list-item-title>
          </template>
          <v-list-item dense sub-group link :to="{name: 'my-contacts'}" exact>
            <v-list-item-content>
              <v-list-item-title class="font-alt">Ma liste de contacts</v-list-item-title>
            </v-list-item-content>
            <v-spacer />
            <v-chip v-if="pendingRequestsCount" x-small class="ma-0" color="secondary" text-color="white">{{ pendingRequestsCount }}</v-chip>
          </v-list-item>
          <v-list-item dense link @click="openInviteContact">
            <v-list-item-content>
              <v-list-item-title class="font-alt">Ajouter un contact</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-if="$auth.granted('role_professionals')" link :to="{name: 'space-members'}">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Membres de l'espace</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list-group no-action class="mb-1" value="expand">
        <template #prependIcon>
          <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
        </template>
        <template v-slot:activator>
          <v-list-item-title>Mes patients</v-list-item-title>
        </template>
        <v-list-item dense sub-group link :to="{name: 'my-patients'}" exact>
          <v-list-item-content>
            <v-list-item-title class="font-alt">Ma liste de patients</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense link @click="openPatientCreate">
          <v-list-item-content>
            <v-list-item-title class="font-alt">Créer un nouveau patient</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-group no-action class="mb-1" value="expand">
        <template #prependIcon>
          <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-class']" class="grey--text text--darken-1" />
        </template>
        <template v-slot:activator>
          <v-list-item-title>Mes groupes</v-list-item-title>
        </template>
        <v-list-item dense sub-group link :to="{name: 'my-teams'}" exact>
          <v-list-item-content>
            <v-list-item-title class="font-alt">Ma liste de groupes</v-list-item-title>
          </v-list-item-content>
          <v-spacer />
          <v-chip v-if="teamPendingRequestsCount" x-small class="ma-0" color="secondary" text-color="white">{{ teamPendingRequestsCount }}</v-chip>
        </v-list-item>
        <v-list-item dense sub-group link @click="openTeamCreate">
          <v-list-item-content>
            <v-list-item-title class="font-alt">Créer un nouveau groupe</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import {Space} from '@/models'
  import * as Contacts from '@/modules/network/api'
  import * as Invitations from '@/modules/teams/api'
  import {mapState} from 'vuex'

  export default {
    name: 'NetworkSidebar',
    data() {
      return {
        pendingRequestsCount: null,
        teamPendingRequestsCount: null,
        value: Boolean
      }
    },
    computed: {
      ...mapState('layout', ['menuDrawerMobile']),
      space() {
        return Space.get(this.$store.state.core.space)
      },
      localDrawer: {
        get() {
          return this.$store.state.layout.menuDrawerMobile
        },
        set(value) {
          this.$store.commit('layout/setDrawerMobile', value)
        }
      }
    },
    async created() {

      this.teamPendingRequestsCount = (await Invitations.list(this.space.uid, ['patient', 'user', 'total_members', 'total_guests'], {
        guest: this.$auth.user.sub
      })).data.list.length

      Contacts.getPendingRequests()
        .then(response => {
          this.pendingRequestsCount = response.data.list.length
        })
        .catch(() => {
          this.Error()
        })
    },
    methods: {
      openInviteContact() {
        this.$root.$emit('modal:open', {
          modal: ()=> import('@/modules/network/components/contacts/InviteContact')
        })
      },
      openTeamCreate() {
        this.$root.$emit('modal:open', {
          modal: ()=> import('@/modules/teams/components/TeamForm')
        })
      },
      openPatientCreate() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/patients/components/PatientForm')
        })
      }
    }
  }
</script>
